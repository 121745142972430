<template>
    <div class="containerb2b11">
      <div class="smart-jackpots text-primary text-center">
          Votre abonnement mensuel a été validé
      </div>
      <div class="flex flex-wrap justify-center">
        <img :src="require('../assets/images/Group 825.svg')">
      </div>
      <div class="Testimonial">
        <div class="Modern-look-trending-design text-center text-primary">
            Félicitation Aziz Ghodbani !
        </div>
        <div class="Get-working-experience-to-work-with-this-amazing-t text-center text-primary">
            Votre abonnement mensuel à notre solution ExVal prend effet immédiatement.<br>
            Vous bénéficiez de tous nos outils pour garantir un travail exceptionnel.
        </div>
      </div>

      <div class="flex flex-wrap justify-center buttons">
        <button class="px-6 text-primary custom-button md:mr-5">
            Retour à la page d’accueil
        </button>
        <button class="px-6 text-white bg-primary custom-button">
            Je me connecte à ExVal
        </button>
      </div>

      <div class="Pour-une-annulation-ou-un-dsabonnement-la-solut text-primary text-center">
        Pour une annulation ou un désabonnement à la solution Exval, vous pouvez à tout moment supprimer votre offre sur la page
        <router-link class="underline" to="/">mon profil</router-link>
      </div>
    </div>
</template>

<script>
    export default {
        name: "B2B11"
    }
</script>

<style scoped>
.smart-jackpots {
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1px;
}
.Testimonial {
    max-width: 836px;
    height: 173px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 50px 302px 39px;
    border-radius: 16px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
}
.Modern-look-trending-design {
    height: 24px;
    padding: 32px 0 24px 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: normal;
}
.Get-working-experience-to-work-with-this-amazing-t {
    height: 61px;
    padding-top: 10px;
    font-size: 16px;
    line-height: 1.88;
}
.buttons {
    margin-top: 50px;
    margin-bottom: 60px;
}
.custom-button{
    border: solid 1px #363660;
    font-size: 12px;
}
.Pour-une-annulation-ou-un-dsabonnement-la-solut {
    height: 24px;
    padding-bottom: 120px;
    font-size: 12px;
    font-weight: 300;
    line-height: 2;
}

@media only screen and (min-width: 360px) {
    .Testimonial {
        width: 95%;
        margin: 50px auto;
        height: auto;
        padding: 5% 5% 40px;
    }
    .Get-working-experience-to-work-with-this-amazing-t {
        height: auto;
    }
    .custom-button {
        margin: 5px;
    }
}
</style>